.scan-wrap {
  overflow: hidden;
  height: 100%;
  .video-wrapper {
    width: 100%;
    height: 95vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
  }

  .zoomed {
    width: auto; /* Let the video adjust naturally */
    height: 100%; /* Ensure it fills the height */
    object-fit: cover;
  }

  .scan-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .scan-box {
    width: 90%;
    margin: auto;
    height: 230px;
    position: absolute;
    top: 32%;
    transform: translateY(-32%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to right, #f0f0f0 100%, transparent 0),
      linear-gradient(to bottom, #f0f0f0 100%, transparent 0),
      linear-gradient(to left, #f0f0f0 100%, transparent 0),
      linear-gradient(to bottom, #f0f0f0 100%, transparent 0),
      linear-gradient(to left, #f0f0f0 100%, transparent 0),
      linear-gradient(to top, #f0f0f0 100%, transparent 0),
      linear-gradient(to right, #f0f0f0 100%, transparent 0),
      linear-gradient(to top, #f0f0f0 100%, transparent 0);

    background-repeat: no-repeat;
    background-position: top left, top left, top right, top right, bottom right,
      bottom right, bottom left, bottom left;
    background-size: 30px 4px, 4px 30px, 30px 4px, 4px 30px, 30px 4px, 4px 30px,
      30px 4px, 4px 30px;
  }
}

.error-text {
  font-size: 1.1rem;
  line-height: 1.5;
}