.product-card-vertical {
  display: flex;
  align-items: start;
  flex-direction: column;
  align-items: start;
  background-color: #fff;
  padding: 8px 12px;
  margin: 6px 0;
  position: relative;

  .product-image {
    width: 110px;
    height: 100px;
    object-fit: contain;
    margin: 0 auto 15px auto;
  }

  .product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .product-name {
    font-size: 0.95rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 0.12rem;
    text-transform: capitalize;
    min-height: 44px;
    max-height: 44px;
    color: #4e4e4e;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .product-brand {
    font-size: 0.75rem;
    color: #2c7765;
    margin: 0;
    text-transform: capitalize;
  }

  .gs-feel {
    font-size: 0.65rem;
    margin-top: 0.25rem;
    margin-bottom: 0;
    color: #4e4e4e;
  }

  .tap-icon {
    color: #9a9a9a;
    position: absolute;
    right: 20px;
    bottom: 20%;
    font-size: 1.35rem;
    opacity: 0.8;
  }

  .product-card-radial {
    position: absolute;
    top: -10px;
    right: 10px;
    z-index: 9;
  }
}
