.search-container {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  overflow: hidden;
  padding: 1.25rem 1rem;

  .input-icon {
    position: absolute;
    top: 42%;
    left: 12px;
    transform: translateY(-42%);
  }

  .search-input {
    width: 92%;
    padding: 15px 38px;
    font-size: 16px;
    background: #f6f6f6;
    border-radius: 10px;
    outline: none;
    margin: 3px 0 10px 0;
    border: 0;
  }

  .clear-icon {
    position: absolute;
    top: 42%;
    right: 50px;
    transform: translateY(-42%);
    cursor: pointer;
    color: #888;
  }

  .search-suggestions {
    margin-top: 8.5rem;
  }

  .empty-state {
    text-align: center;
    color: #b0b0b0;

    .empty-icon {
      font-size: 50px;
      color: #ccc;
      margin-bottom: 10px;
    }

    .empty-text {
      font-size: 1rem;
      font-weight: 600;
      margin: 1rem auto 0.5rem auto;
      width: 70%;
    }

    .helper-text {
      font-size: 14px;
      color: #a0a0a0;
    }
  }
}

.most-searched {
    .most-searched-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .most-searched-list {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .most-searched-item {
            font-size: 14px;
            background-color: #f0f0f0;
            border-radius: 5px;
            padding: 8px 12px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #e0e0e0;
            }

            .most-searched-link {
                text-decoration: none;
                color: #333;
            }
        }
    }
}
