@import "../../Styles/variables";

.chartTitle {
  padding: 50px 0;
  font-family: "Chivo";
  font-weight: 900;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #2c7765;
  margin-bottom: 0;

  @media (max-width: 767px) {
    padding: 35px 0 15px 0;
    font-size: 28px;
    line-height: 32px;
    max-width: 302px;
    margin: auto;
  }
}

#pdp__gs__chart {
  border-radius: 10px;
  margin-bottom: 30px;

  .product_dec_last_scrolled {
    padding: 0 !important;
  }

  .product_dec_last_scrolled_left {
    background: #fcfcfc !important;
  }

  .main-chart-element__ {
    .First.Text {
      max-width: 412px;
      padding: 0;

      & > p {
        font-family: "Overpass";
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: #4e4e4e;
      }
    }

    .first_content {
      font-family: "Overpass";
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      color: #2c7765;
      padding: 0;
      margin-bottom: 30px;
    }
  }

  @media (max-width: 767px) {
    border: 0;
    padding-bottom: 0 !important;

    .product_dec_last_scrolled_left {
      background: transparent !important;
      padding-top: 0px !important;
      padding-bottom: 30px !important;
      margin-bottom: 24px;
    }

    .main-chart-element__ {
      .first_content {
        font-size: 16px;
        line-height: 24px;
        max-width: 270px;
        margin: 0px auto 16px auto;
      }

      .First.Text {
        max-width: 270px;
        margin: 0px auto;
        height: auto !important;

        p {
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }
}

#gs-chart-for-hp {
  overflow: hidden;
  min-height: 400px;

  .gs-chart-lp-wrap {
    width: 100%;

    .product_dec_last_scrolled_left {
      justify-content: center;
      align-items: center;
    }

    .product_dec_last_scrolled_right {
      h2 {
        margin-left: 0px;
      }
    }

    .product_dec_last_scrolled_right .First.Text {
      height: 390px;
      margin: 20px auto;
    }
  }

  .product_dec_last_scrolled_holder
    .product_dec_last_scrolled
    .product_dec_last_scrolled_right
    .food__court {
    max-width: 85%;
    margin-right: auto;
  }

  .product_dec_last_scrolled_holder
    .product_dec_last_scrolled
    .product_dec_last_scrolled_right
    .accordion
    .accordion__item
    .AccordionItemHeading_bottom
    h3 {
    font-size: 18px;
    overflow: hidden;
    max-width: 350px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
    flex: auto;
  }

  #main-chart-element {
    height: 500px;
  }

  .product_dec_last_scrolled {
    border: 0;
    padding: 0 60px;
  }

  .product_dec_last_scrolled_left {
    background: #fff;
  }
}

.gs-chart-slider .ant-progress .ant-progress-text {
  font-size: 12px;
}

.gs-chart-lp #related-prdcts-gs-chart {
  margin-top: 30px;

  h3 {
    text-align: center;
    font-size: 36px;
    line-height: 42px;
    font-weight: 900;
    font-family: "Chivo", "sans-serif";
    color: #4e4e4e;
    margin: auto;
    margin-bottom: 50px;
  }

  @media (max-width: 767px) {
    margin-bottom: 30px;

    h3 {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 767px) {
  #gs-chart-for-hp #main-chart-element {
    height: auto !important;
    padding: 0;
  }

  #gs-chart-for-hp {
    .product_dec_last_scrolled_holder
      .product_dec_last_scrolled
      .product_dec_last_scrolled_right
      .food__court {
      max-width: 100% !important;
      margin: auto;

      .accordion {
        padding: 0px 20px !important;

        .accordion__item .AccordionItemHeading_bottom {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
    }

    .gs-chart-lp-wrap .product_dec_last_scrolled_right .First.Text {
      height: 120px !important;
      margin: 0 auto;
      padding-bottom: 0;
    }
  }

  .gs-chart-lp {
    padding: 0 !important;

    h2 {
      font-size: 28px !important;
      padding: 0 15px !important;
      line-height: 33px !important;
    }

    .container {
      padding: 0px;
    }

    .container > svg {
      top: 110px !important;
    }

    .gs-chart-custom-slider {
      margin-top: 60px !important;
    }

    .swiper-slide .gs-chart-slider {
      margin: 0px 4px !important;

      img {
        height: 85px !important;
        margin-top: 10px !important;
      }
    }

    .swiper-slide .gs-chart-slider .ant-progress {
      position: relative;
      top: 8px;
      z-index: 9;
      width: 32px;
      height: 32px;

      & .ant-progress-inner {
        width: 32px !important;
        height: 32px !important;
      }

      & svg {
        width: 32px;
        height: 32px;

        & span {
          font-size: 12px;
        }
      }

      .ant-progress-text {
        font-size: 12px;
      }
    }

    .gs-chart-lp-wrap {
      grid-template-columns: 1fr !important;
      padding: 0 15px;

      .product_dec_last_scrolled_holder {
        .product_dec_last_scrolled {
          padding: 0px !important;
        }
      }
    }
  }
}

.prdct-detail-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & .p_top {
    margin-bottom: -20px;
  }

  & .prdct-detail {
    border: none;
  }

  & .details {
    & .big-prdct {
      height: 100%;
      display: flex;
      flex-direction: column;

      & .price_edec {
        & .prdct-price {
          font-family: Overpass;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;
          letter-spacing: 0em;
          text-align: left;
          padding: 0px;

          & .main-heading {
            font-size: 24px;
            // color: #4e4e4e !important;
            // font-weight: normal !important;
          }
        }
      }
    }

    & .product__bottom_last {
      margin-top: auto;

      & > a {
        font-family: Overpass;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        border: 1px solid #6b9080;
        border-radius: 30px;
        padding: 8px 15px;
        display: inline-flex;
        text-decoration: none;
        margin-right: 10px;
        margin-top: 5px;
      }
    }

    & .no_add_to_cart {
      & ~ [class*="col"] {
        & h1 {
          border: none;
          padding: 0;
        }
      }
    }
  }

  & .select_size_checkox_holder {
    display: flex;
    overflow: hidden;
    margin-top: 20px;
    flex-wrap: wrap;

    & h5 {
      font-family: Overpass;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      flex: 0 0 100%;
    }

    & .select_size_checkox {
      display: inline-block;
      position: relative;
      overflow: hidden;
      font-size: 0;
      margin-right: 10px;

      & input {
        -webkit-appearance: none;
        height: 35px;
        border: 1px solid #a4c3b2;
        min-width: 90px;
        padding: 5px 10px;
        background: white;
        border-radius: 5px;
        margin: 0;
        cursor: pointer;

        &:checked ~ span {
          background: #6b9080;
          border-color: #6b9080;
          color: #fff;
          border-radius: 5px;
        }
      }

      & span {
        font-family: Overpass;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #4e4e4e;
        position: absolute;
        left: 0;
        display: flex;
        top: 0;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        pointer-events: none;
      }

      // & input{
      // 	&:checked{
      // 		 ~ span{color:#fff !important}
      // 	}
      // }
    }
  }

  & .noBorder {
    border: none !important;
  }

  & .cart_some_where {
    font-family: Overpass;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #e34d4d;
    letter-spacing: 0em;
    text-align: left;
    max-width: 75%;
    margin-bottom: 30px;
    padding-top: 15px;

    & svg {
      margin-right: 10px;
    }
  }

  & .product_dec_cored {
    width: 100%;
    background: #fdf4e0;
    padding: 45px 0;

    & p {
      font-family: Overpass;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
      color: #4e4e4e;
      max-width: 738px;
      margin: auto;
      margin-bottom: auto;
      margin-bottom: 0;
    }
  }

  & .product_dec_3box {
    // background:#F0F7F5;
    // padding:40px 0;
    & .product_dec_3box_holder {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 30px 6vw;
      text-align: center;

      & .product_dec_3box_holder_box {
        display: grid;
        grid-template-columns: 20% 1fr;
        align-items: center;

        & .icon {
          height: 50px;
          margin-bottom: 15px;
          display: block;
        }

        & h3 {
          //styleName: H2 Chivo;
          font-family: Chivo;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: center;
          margin-bottom: 12px;
        }

        & p {
          text-align: center;
          margin: 0px;

          & > a {
            font-family: Overpass;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;
            margin: 4px 14px;
            display: inline-flex;
            color: #2c7765;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  & .product_dec_1box {
    // padding:100px 0;
    & .nutrition_content_title,
    & .nutrition_content_list {
      font-family: Overpass;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: center;
      max-width: 870px;
      margin: auto;
      color: #4e4e4e;
    }

    & h4 {
      font-family: Chivo;
      font-size: 28px;
      font-style: normal;
      font-weight: 400;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: center;
      border-bottom: 1px solid #dbdbdb;
      color: #58b078;
      max-width: 795px;
      margin: auto;
      margin-bottom: auto;
      padding-bottom: 30px;
      margin-bottom: 40px;
    }
  }

  & .product_dec_last_scrolled_holder {
    // padding:80px 0;
    // padding-top:0px;
    & .product_dec_last_scrolled {
      border: 1px solid #ddd;
      border-radius: 10px;
      overflow: hidden;
      display: grid;
      grid-template-columns: 40% 1fr;

      & .product_dec_last_scrolled_left {
        background: #fcfcfc;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

        & h4 {
          font-family: Chivo;
          font-size: 28px;
          font-style: normal;
          font-weight: 400;
          line-height: 33px;
          letter-spacing: 0em;
          text-align: center;
          max-width: 90%;
          margin-bottom: 20px;
          color: #4e4e4e;
        }
      }

      & .product_dec_last_scrolled_right {
        height: 620px;
        padding: 20px 0;

        & .food__court {
          display: none;
          max-width: 75%;
          margin: auto;

          &.displayed {
            display: flex;
            flex-direction: column;
          }
        }

        & .ps__rail-y {
          right: 20px !important;
          background: #f0f7f5 !important;
          width: 6px !important;
          opacity: 1 !important;
          border-radius: 6px !important;
          overflow: hidden;

          & .ps__thumb-y {
            background: #54aa95 !important;
            opacity: 1 !important;
            width: 100% !important;
          }
        }

        & p {
          font-family: Overpass;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: #6b9080;
        }

        & .full_scroll {
          height: 100%;
          max-width: 410px;
          margin: auto;
          display: flex;
          align-items: first baseline;
          flex-direction: column;
          justify-content: center;

          & i {
            margin-bottom: 20px;
          }
        }

        & .accordion {
          border: none;
          padding: 30px 0px;

          & .accordion__item {
            border-bottom: 0;

            & .accordionItemHeading_top {
              & h2 {
                //styleName: Homepage H1;
                font-family: Chivo;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
                color: #4e4e4e;
                margin-bottom: 34px;
              }
            }

            & .AccordionItemHeading_bottom {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 50px;

              & h3 {
                width: 100%;
                text-overflow: ellipsis;
                overflow: hidden;
                flex: 1 0 50%;
                white-space: nowrap;

                @media (max-width: 767px) {
                  font-size: 18px !important;
                }
              }

              @media (max-width: 767px) {
                margin-bottom: 20px !important;
              }

              & h3 {
                font-family: Overpass;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                letter-spacing: 0em;
                text-align: left;
                color: #cc4a38;
                margin: 0px;

                &.green__color {
                  color: #6ec22b;
                }

                &.broun__color {
                  color: #d0862f;
                }
              }

              & a {
                background: #f0f7f5;
                border-radius: 30px;
                background: #f0f7f5;
                font-family: Overpass;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #6b9080;
                display: inline-flex;
                align-items: end;
                justify-content: center;
                padding: 8px 16px;

                & svg {
                  margin-right: 10px;
                }
              }

              @media (max-width: 767px) {
              }
            }
          }

          & .AccordionItemHeading_last {
            font-family: Overpass;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #4e4e4e;
            margin-top: 30px;
            margin-bottom: 20px;

            & svg,
            & img {
              margin-right: 20px;
            }
          }

          & .accordion__heading {
            & .accordion__button {
              background: none;
              min-height: 76px;
              display: flex;
              justify-content: flex-start;
              font-family: Overpass;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 26px;
              letter-spacing: 0em;
              text-align: left;
              align-items: center;
              padding: 14px 0;
              position: relative;
              padding-right: 30px;
              border-bottom: 1px solid #ddd;
              color: #494949;

              &:before {
                position: absolute;
                right: 0px;
                transform: rotate(45deg) translateY(-50%);
                top: 44%;

                @media (max-width: 767px) {
                  top: 48%;
                }
              }

              &[aria-expanded="true"] {
                &:before {
                  transform: rotate(224deg) translateY(-50%);
                  right: -7px;
                }
              }

              & .icon {
                margin-right: 20px;
                flex: 0 0 20px;
                max-width: 20px;

                & svg {
                  width: 18px;
                  height: 18px;
                }
              }

              & span {
                font-family: Overpass;
                font-size: 14px;
                font-style: italic;
                font-weight: 400;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                padding-right: 50px;
                margin-left: auto;
              }

              &[aria-expanded="true"] {
                border: none;

                & .icon {
                  & .tabs-dot {
                    display: inline-flex;

                    & span {
                      display: inline-flex;
                    }
                  }
                }

                & span {
                  display: none;
                }
              }
            }

            & .accordion__panel {
              padding: 0px;

              & .range-slider {
                position: relative;
                width: 90%;
                margin-right: 5%;
                margin-left: 5%;

                & .triangle {
                  border-left: solid 6px transparent !important;
                  border-right: solid 6px transparent !important;
                }
              }

              & .info {
                & p {
                  & svg {
                    display: none;
                  }
                }
              }
            }

            & .accordian_panel {
              & h4 {
                font-family: Overpass;
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #4e4e4e;
              }

              & p {
                font-family: Overpass;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 17px;
                letter-spacing: 0em;
                text-align: left;
                color: #929292;
                padding: 15px;
                padding-bottom: 0;

                & svg {
                  margin-right: 10px;
                }
              }

              &:not(:last-child) {
                margin-bottom: 25px;
              }
            }
          }
        }

        & .food__court {
          & .accordion {
            width: 100%;

            & .main-item {
              & .accordion__button {
                line-height: initial !important;
                min-height: inherit;
                padding: 0;
                margin-top: 30px;
                font-size: 16px !important;
                margin-bottom: -2px;
              }
            }

            & .accordion__panel {
              padding: 0px !important;

              & .information {
                font-family: Overpass;
                font-size: 16px;
                font-style: italic;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #4e4e4e;
                margin-top: 20px;
                margin-bottom: -5px;

                ~ .info {
                  margin-bottom: 20px !important;
                }
              }

              & .AccordionItemHeading_last {
                margin-top: 30px !important;
                margin-bottom: 30px !important;
              }

              & .range-slider {
                & .triangle {
                  display: none;
                }

                margin-bottom: 10px;

                & .range-tooltip {
                  top: -55px;

                  &:before {
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 8px solid red;
                    position: absolute;
                    bottom: -14px;
                    // border-bottom-color:rgb(147, 208, 47);
                    border-bottom-color: #616161;
                    content: "";
                  }

                  & .range-per {
                    font-size: 20px;
                    margin-bottom: -4px;

                    & .range-text {
                      font-size: 10px;
                      color: #a1a1a1;
                    }
                  }
                }
              }

              & .range-width-container {
                font-size: 10px;
                color: #a1a1a1;

                & div {
                  &:after {
                    position: absolute;
                    left: 42%;
                    content: "";
                    top: -9px;
                    height: 5px;
                    width: 2px;
                    background: #a1a1a1;
                  }
                }
              }

              & .info {
                margin-top: 10px;

                & p {
                  padding: 0px !important;
                  margin: 0px;

                  & svg {
                    display: none;
                  }
                }
              }

              & .accordian_panel {
                & p {
                  display: flex;
                  font-family: Overpass;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 17px;
                  letter-spacing: 0em;
                  text-align: left;

                  & svg {
                    margin-right: 10px;
                    display: none;
                  }
                }
              }
            }

            & .accordion__item {
              & .accordion__heading {
                & .accordion__button {
                  font-family: Overpass;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 15px;
                  letter-spacing: 0em;
                  text-align: left;

                  & .icon {
                    margin-right: 10px;

                    & #Capa_1 {
                      width: 14px;
                      height: 14px;
                      fill: #e34d4d !important;
                    }
                  }
                }
              }

              & .accordion__panel {
                padding: 0px !important;

                & .accordian_panel {
                  & p {
                    display: flex;

                    & svg {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }
          }
        }

        & .Environment__court__ac {
          & .accordion {
            & .accordion__item {
              border: none;
              margin-bottom: 50px;
            }
          }
        }

        & .Environment__court {
          padding: 0;

          & .foot__print {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #ddd;
            padding-bottom: 20px;
            margin-bottom: 20px;

            & .foot__print_left {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              & .foot__print_dec_svg {
                flex: 0 0 50px;
                max-width: 50px;
                height: 70px;
                border-radius: 4px;
                margin-right: 20px;
                background: #fff;

                & img {
                  height: 100%;
                }
              }

              & .foot__print_dec {
                & h3 {
                  font-family: Overpass;
                  font-size: 18px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 22px;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #54aa95;
                  margin-bottom: 15px;
                }

                & p {
                  font-family: Overpass;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 17px;
                  letter-spacing: 0em;
                  text-align: left;
                  font-family: Overpass;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 17px;
                  letter-spacing: 0em;
                  text-align: left;
                  margin: 0px;

                  & svg {
                    margin-right: 10px;
                  }
                }
              }
            }

            & .foot__print_inner_right {
              flex: 0 0 auto;
              margin-left: auto;
              display: flex;
              flex-direction: column;
              font-family: Overpass;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 15px;
              letter-spacing: 0em;
              text-align: right;
              color: #a1a1a1;

              & span {
                font-family: Overpass;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: right;
              }
            }
          }
        }

        & .food__court {
          margin-bottom: 0;
        }

        & .food__court {
          &.processing,
          &.food-safety {
            & .accordion {
              & .accordion__heading {
                & .accordion__button {
                  font-family: Overpass;
                  font-size: 14px !important;
                  font-style: normal;
                  font-weight: 600 !important;
                  line-height: 18px !important;
                  letter-spacing: 0em;
                  text-align: left;
                  color: #494949;
                }
              }
            }
          }
        }
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        border: none;

        & .product_dec_last_scrolled_left {
          padding: 20px 20px 40px 20px;
          background: #fff;

          & h4 {
            font-family: Chivo;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: center;
            max-width: 70%;
          }
        }

        & .product_dec_last_scrolled_right {
          height: auto;

          & .scrollbar-container {
            & .First.Text {
              max-width: 90%;

              & .first_content {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                max-width: 275px;
                margin: 0 auto 20px auto;
                padding: 0;
              }

              & p {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                padding: 0 15px;
              }
            }

            & .accordion {
              padding: 8px 15px;

              & .accordion__item {
                & .accordionItemHeading_top {
                  display: none;
                }

                & .AccordionItemHeading_bottom {
                  & a {
                    margin: 10px 0;
                  }
                }
              }

              & .icon {
                flex: 0 0 20px;
                max-width: 20px;
                position: relative;
                width: auto;
                height: auto;
                margin-right: 10px !important;

                & svg {
                  width: 15px !important;
                  height: 15px !important;
                }

                & .tabs-dot {
                  position: relative !important;
                  padding: 0 !important;
                  display: flex;
                  width: auto !important;
                  height: auto !important;

                  & .dot-inner {
                    padding: 0 !important;
                    display: flex;
                    top: -1px;
                    position: relative;
                  }
                }
              }

              & .accordion__button {
                font-size: 16px;
                min-height: inherit;
                padding: 15px 0;

                & span {
                  display: none;
                }
              }
            }

            & .Environment__court.food__court {
              padding: 0 15px;
            }
          }
        }
      }
    }

    @media (max-width: 767px) {
      padding: 0px 0 10px 0 !important;
    }
  }
}

.chart__svg__holder {
  width: 325px;
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 30px;

  & .quarteree-circle-top-left,
  & .quarteree-circle-top-right,
  & .quarteree-circle-bottom-left,
  & .quarteree-circle-bottom-right {
    background: #ddd;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 0 0 200px 0;
    -moz-border-radius: 0 0 200px 0;
    -webkit-border-radius: 200px 0 0 0;
    width: 50%;
    height: 50%;
    border-left: none;

    &:after {
      content: "";
      background: #fcfcfc;
      position: absolute;
      top: 4px;
      left: 4px;
      height: 100%;
      width: 100%;
      border-radius: 200px 0 0 0;
    }
  }

  & .sv__icon {
    position: absolute;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Overpass;
    pointer-events: none;
    z-index: 9;

    @media (max-width: 767px) {
      width: 40px;
      height: 40px;
    }

    & svg {
      width: 100%;
      height: 100%;
      fill: #333 !important;

      & path {
        // fill:#333;
        &.fill__none {
          fill: none;
        }
      }
    }

    // &:before {
    // 	content: attr(data-text);
    // 	position: absolute;
    // 	top: -30%;
    // 	transform: translateY(-50%);
    // 	left: -50%;
    // 	margin-left: 0;
    // 	width: auto;
    // 	padding: 10px;
    // 	border-radius: 10px;
    // 	background: none;
    // 	color: #000;
    // 	text-align: center;
    // 	font-size: 16px;
    // 	font-style: normal;
    // 	font-weight: 700;
    // 	line-height: 20px;
    // 	letter-spacing: 0em;
    // 	text-align: left;
    // 	color:#6B9080;
    // 	display: none;
    //   }
    // &:hover:before{
    // 	display: block;
    // }
  }

  & .quarteree-circle-top-left {
    left: -4px;

    &:hover {
      & .sv__icon {
        &:before {
          display: block;
        }
      }
    }
  }

  & .quarteree-circle-top-right {
    border-radius: 0 200px 0 0;
    left: auto;
    right: -4px;
    top: -1px;
    // border-left: 5px solid #FCFCFC;
    // border-bottom:3px solid #FCFCFC;
    border: 0;

    &:after {
      border-radius: 0 200px 0 0;
      top: 4px;
      left: auto;
      right: 4px;
    }

    & .sv__icon {
      left: auto;
      right: 50%;
      top: 60%;
      border: auto;
      transform: translateY(-50%);
    }
  }

  & .quarteree-circle-bottom-left {
    border-radius: 0 0 0 200px;
    left: -4px;
    right: auto;
    top: auto;
    bottom: -2px;
    border: none;
    border-top: 4px solid #fcfcfc;

    &:after {
      border-radius: 0 0 0 200px;
      top: auto;
      bottom: 4px;
      left: 4px;
      right: auto;
    }

    & .sv__icon {
      left: 50%;
      right: auto;
      bottom: 50%;
      top: auto;
    }
  }

  & .quarteree-circle-bottom-right {
    border-radius: 0 0 200px 0;
    left: auto;
    right: -4px;
    top: auto;
    bottom: -2px;
    // border-top: 4px solid #FCFCFC;
    // border-left: 4px solid #FCFCFC;
    border: 0;

    &:after {
      border-radius: 0 0 200px 0;
      top: -4px;
      bottom: 0;
      left: -4px;
      right: 0;
      right: auto;
    }

    & .sv__icon {
      left: auto;
      right: 50%;
      bottom: 50%;
      top: auto;
    }
  }

  & .chart__svg {
    width: 90%;
    height: 90%;
    overflow: hidden;
    border-radius: 100%;
    position: relative;
    display: flex;

    & .chart__number {
      width: 78.72px;
      height: 78.72px;
      background: #ffffff;
      border: 3px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px -5px 10px rgba(235, 235, 235, 0.25),
        0px 4px 10px rgba(133, 133, 133, 0.25), inset 0px -6px 12px #eeeeee,
        inset 0px 5px 10px #dbdbdb;
      transform: matrix(1, 0, 0, -1, 0, 0);
      font-family: Overpass;
      font-size: 30px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      top: 50%;
      z-index: 99;
      border-radius: 100%;
      transform: translateY(-50%);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: initial;
      color: #65be3b;

      & span {
        position: relative;
        top: 2px;
      }
    }

    .quarter-circle-top-left {
      background: #f0f7f5;
      border-top: 4px solid #fff;
      border-left: 4px solid #fff;
      position: absolute;
      top: -2px;
      left: -2px;
      border-radius: 200px 0 0 0;
      -moz-border-radius: 200px 0 0 0;
      -webkit-border-radius: 200px 0 0 0;

      &:hover {
        cursor: pointer;
      }

      & .quarter_inner {
        border-radius: 200px 0 0 0;
        -moz-border-radius: 200px 0 0 0;
        -webkit-border-radius: 200px 0 0 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      }
    }

    .quarter-circle-top-right {
      background: #f0f7f5;
      position: absolute;
      top: -2px;
      right: -2px;
      border-top: 4px solid #fff;
      border-radius: 0 200px 0 0;
      -moz-border-radius: 0 200px 0 0;
      -webkit-border-radius: 0 200px 0 0;

      &:hover {
        cursor: pointer;
      }

      & .quarter_inner {
        border-radius: 0 200px 0 0;
        -moz-border-radius: 0 200px 0 0;
        -webkit-border-radius: 0 200px 0 0;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      }
    }

    .quarter-circle-bottom-left {
      background: #f0f7f5;
      position: absolute;
      bottom: -2px;
      left: -2px;
      border-left: 4px solid #fff;
      border-radius: 0 0 0 200px;
      -moz-border-radius: 0 0 0 200px;
      -webkit-border-radius: 0 0 0 200px;

      &:hover {
        cursor: pointer;
      }

      & .quarter_inner {
        border-radius: 0 0 0 200px;
        -moz-border-radius: 0 0 0 200px;
        -webkit-border-radius: 0 0 0 200px;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        left: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      }
    }

    .quarter-circle-bottom-right {
      background: #f0f7f5;
      position: absolute;
      bottom: -2px;
      right: -2px;
      border-radius: 0 0 200px 0;
      -moz-border-radius: 0 0 200px 0;
      -webkit-border-radius: 0 0 200px 0;

      &:hover {
        cursor: pointer;
      }

      & .quarter_inner {
        border-radius: 0 0 200px 0;
        -moz-border-radius: 0 0 200px 0;
        -webkit-border-radius: 0 0 200px 0;
        position: absolute;
        width: 100%;
        height: 100%;
        bottom: 0;
        right: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
      }
    }

    [class*="quarter-circle-"] {
      width: 50% !important;
      height: 50% !important;
    }
  }

  @media (max-width: 767px) {
    width: 280px;
    height: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.nutrition__image {
  & .cls-1 {
    font-size: 25px;
    fill: #4e4e4e;
    font-family: Overpass-Bold, Overpass;
    font-weight: 700;
  }

  position: absolute;
  left: -7px;
  top: 8px;
  width: 74%;
  margin: 6px;
  opacity: 0.8;
  transform: rotate(-3deg);
  ~ .sv__icon {
    display: none !important;
  }
}

.processing__image {
  & .cls-1 {
    font-size: 25px;
    fill: #4e4e4e;
    font-family: Overpass-Bold, Overpass;
    font-weight: 700;
  }

  position: absolute;
  right: -10px;
  top: 7px;
  width: 73%;
  margin: 5px;
  transform: rotate(5deg);
  opacity: 0.8;

  ~ .sv__icon {
    display: none !important;
  }
}

.environment__image {
   & .environment__image__svg {
     width: 82%;
     font-family: Overpass-Bold, Overpass;
     opacity: 0.75;
   }
  & .cls-1 {
    font-size: 27px;
    fill: #4e4e4e;
    font-family: Overpass-Bold, Overpass;
    font-weight: 700;
  }

  position: absolute;
  left: -12px;
  bottom: -36px;
   width: 70%;
  margin: 6px;
  transform: rotate(-1deg);
  opacity: 0.75;

  ~ .sv__icon {
    display: none !important;
  }

   @media(max-width:767px){
       left: -10px;
       bottom: -43px;
       transform: rotate(-1deg);
   }
}

.food___image {
   & .ingredient__image__svg {
     width: 75%;
     font-family: Overpass-Bold, Overpass;
     opacity: 0.75;
   }
  & .cls-1 {
    font-size: 25px;
    fill: #4e4e4e;
    font-family: Overpass-Bold, Overpass;
    font-weight: 700;
  }

  position: absolute;
  right: -52px;
  bottom: -28px;
  width: 82%;
  margin: 6px;
  transform: rotate(-3deg);
  opacity: 0.8;

  ~ .sv__icon {
    display: none !important;
  }
   
   @media(max-width:767px){
       right: -46px;
       bottom: -37px;
       transform: rotate(-4deg);
   }
}

.product-block {
  width: 100%;
  display: flex;

  .left-column {
    width: 32%;
    padding: 50px 15px 58px 0;
    border-right: 1px solid $border;
  }

  .right-column {
    width: 68%;
    padding: 50px 0 58px 65px;
  }

  @media (max-width: 1200px) {
    .left-column {
      padding: 40px 15px 40px 0;
    }

    .right-column {
      padding: 40px 0 40px 40px;
    }
  }

  @media (max-width: 1023px) {
    flex-direction: column;

    .left-column {
      width: 100%;
      padding: 30px 20px 60px 20px;
      border-right: none;
      border-bottom: 1px solid $border;
    }

    .right-column {
      width: 100%;
      padding: 30px 20px 30px 20px;
      border-left: none;
    }
  }
}

.gs-chart-lp .first_content {
  font-family: "Overpass";
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  color: #2c7765;
  padding: 0;
  margin-bottom: 30px;
  padding-top: 20px;
}

// .hide-before::before{
//   display: none !important;
// }
.successColor {
  color: #494949;
}

.falseColor {
  color: #d6505e;
}

.hide-after::before {
  visibility: hidden;
}

.cancelHover:hover {
  cursor: default !important;
}

.hidden-arrow::before {
  display: none;
}

.hid {
  .accordion__button::before {
    visibility: hidden;
  }
}

.n-border {
  .accordion__button {
    border-bottom: none !important;
  }
}

.info {
  p {
    font-family: Overpass;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 17px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #929292 !important;
    padding: 15px !important;
    padding-bottom: 0 !important;
  }
}

.tabs-dot {
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0 !important;
  height: 8px;
  width: 8px;

  .dot-inner {
    border-radius: 50%;
    padding-right: 0 !important;
    height: 8px;
    width: 8px;
  }
}

.circle-selected {
  background-color: #6b6767 !important;
  transform: scale(1.01);
}

.small-heading {
  font-family: Overpass;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

ol {
  li {
    border-bottom: none !important;
  }
}

.selectedState {
  border-bottom: 1px solid black !important;
}

.hid {
  .accordion__button {
    // border-bottom-color: transparent !important;
  }
}

.stickyATC_product_right .sticky_product_quantity {
  padding: 0 12px;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

// .stickyATC_product_right .prdct-price span{
//   display: block;
// }

//modal css
.ant-modal-body .modal-main {
  padding-top: 10px;

  @media (max-width: 767px) {
    padding-top: 0;
  }
}

.ant-modal-body .ant-modal-close {
  top: 28px;
}

.chart-modal-title {
  color: #6b9080 !important;
  padding: 0 0 20px 0;
  border-bottom: 1px solid $border;
  font-weight: 700;
  font-family: $font-secondary;
  margin-bottom: 20px;

  svg {
    margin-right: 15px;

    @media (max-width: 767px) {
      height: 20px;
      width: 20px;
      margin-right: 8px;
    }
  }

  @media (max-width: 776px) {
    padding: 0 0 15px 0;
  }
}

.chart-description-modal {
  & .main-heading {
    font-size: 20px !important;

    @media (max-width: 767px) {
      font-size: 16px !important;
    }
  }

  & .modal-content {
    border-color: #fff;
    border-radius: 10px;
  }

  & .info-1 {
    margin-bottom: 20px;
  }

  & .modal-info {
    color: #4e4e4e;
    font-weight: 300;
    font-family: $font-primary;
    font-size: 16px;

    @media (max-width: 767px) {
      font-size: 14px !important;
      padding-bottom: 10px;
    }
  }

  & .info-header {
    color: #6b9080 !important;
    font-family: Chivo;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;

    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}

.product-detail-page-size-options {
  margin: 10px 0 0 0;

  & p {
    font-family: Overpass;
    font-weight: bold;
    margin-bottom: 4px;
    font-size: 12px;
    color: #4e4e4e;
  }

  & ul li button {
    border: 1px solid #a4c3b2;
    padding: 4px 15px;
    border-radius: 5px;
    background: #fff;
    font-family: Overpass;
    color: #4e4e4e;
    font-size: 16px;

    &.selected-size {
      color: #fff;
      background: #6b9080;
    }
  }
}

.product_dec_3box_holder_box_info {
  & h3,
  & p {
    text-align: left !important;
  }

  & p {
    & a {
      border: 1px solid #6b9080;
      border-radius: 30px;
      padding: 6px 12px;
      margin: 0 !important;
      margin-right: 10px !important;
      margin-bottom: 8px !important;
    }
  }
}

.nutrition_content_title {
  text-align: left !important;
  font-family: Chivo !important;
}

.new_pdp_info {
  background: #fafafa;
}

.product_dec_last_scrolled_holder h1 {
  color: #4e4e4e !important;
  margin-bottom: 45px;
}

@media (max-width: 767px) {
  .product_dec_last_scrolled_holder h1 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 22px;
  }

  .prdct-detail-main
    .product_dec_3box
    .product_dec_3box_holder
    .product_dec_3box_holder_box {
    grid-template-columns: 100% !important;
  }

  .prdct-detail-main .product_dec_3box .product_dec_3box_holder {
    grid-gap: 30px !important;
  }

  .prdct-detail-main
    .product_dec_3box
    .product_dec_3box_holder
    .product_dec_3box_holder_box
    h3 {
    text-align: center !important;
    margin-bottom: 15px !important;
    position: sticky;
    left: 0;
  }

  .combo_on_pdp .combo_sec_price.more_product_price {
    margin-top: 0px !important;
  }

  .combo_on_pdp .combo_sec_pro .current_prdct_item {
    width: 200px;
  }

  .product_dec_3box_holder_box_info {
    overflow: hidden;

    & a {
      min-width: 130px;
      justify-content: center;
      align-items: center;
    }
  }

  .product_dec_3box_holder_box_info p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .prdct-detail-main .product_dec_1box h2 {
    text-align: center !important;
    font-size: 24px !important;
  }

  .prdct-detail-main .product_dec_1box.product_dec_1box_last h2 {
    font-size: 20px !important;
  }

  .prdct-detail-main .product_dec_3box {
    padding-top: 0px !important;
  }

  .nutrition_content_title {
    text-align: center !important;
    line-height: 21px !important;
  }

  .nutrition_content_list {
    padding: 20px 0 !important;
    line-height: 30px !important;
    text-align: center !important;
  }

  .nutrition_facts_tab h2 {
    font-size: 20px;
    padding: 15px 18px 25px 18px;
    position: relative;
    font-weight: normal;

    & .hide_when_open_one {
      font-size: 8px;
      line-height: 8px;
      padding-right: 3px;
      display: none;
    }

    & .hide_when_open_one_for_sm {
      display: block;
      font-size: 10px;
      color: #929292;
      position: absolute;
      left: 20px;
      top: 35px;
    }

    & .hide_when_open_two {
      font-size: 16px;
      line-height: 18px;
      padding-right: 8px;
      padding-left: 0px;
      padding-top: 10px;
    }
  }

  .nutrition_facts_tab svg {
    width: 12px;
    height: 12px;
  }

  .nutrition_facts_tab .nutrition_facts_tab_chart {
    grid-template-columns: 100%;

    & > div {
      border: 0 !important;
    }

    & > div:nth-child(1) {
      padding-top: 30px !important;
      padding-left: 0px !important;
    }

    & table {
      width: 100%;

      & th {
        border-top: 1px solid #ddd;
      }
    }
  }

  .progress_on_new_pdp .ant-progress-circle,
  .progress_on_new_pdp .ant-progress-inner {
    width: 55px !important;
    height: 55px !important;
  }

  .new_pdp_info {
    padding-bottom: 20px;
    margin-bottom: 50px;
  }

  .nutrition_facts_tab {
    margin: 0;
  }

  #product_detail
    .details
    .big-prdct
    .custom_increment
    .increment_decrement_custom.increment_decrement
    .product_count {
    font-size: 16px !important;
    margin-top: 4px !important;
  }

  #product_detail .details .big-prdct .custom_increment .decrement svg {
    width: 12px;
    height: 12px;
  }
}

.lazy-load-image-background.lazy-load-image-loaded {
  height: inherit;
}

// zoom image
.perimeter {
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.image {
  flex: 0 0 100%;
}

.copy {
  padding: 20px;
  font-family: sans-serif;
}

@media (min-width: 415px) {
  .perimeter {
    flex-direction: row;
  }

  .image {
    flex: 0 0 50%;
  }
}

@media (min-width: 800px) {
  .image {
    flex: 0 0 33.5%;
  }
}

.progress_on_new_pdp .ant-progress-circle .ant-progress-text {
  font-size: 20px;
  line-height: 28px;
}

.prdct-detail-main
  .product_dec_last_scrolled_holder
  .product_dec_last_scrolled {
  position: relative;
}

@media (max-width: 991px) and (min-width: 768px) {
  .preview .preview-pic.tab-content img {
    object-fit: contain !important;
  }

  .stickyATC_product .stickyATC_product_left .stickyATC_product_right_img h4 {
    font-size: 18px !important;
  }

  .prdct-detail-main
    .product_dec_last_scrolled_holder
    .product_dec_last_scrolled,
  #join-as-member .join-as-member-pro {
    grid-template-columns: 1fr !important;
  }

  .prdct-detail-main
    .product_dec_last_scrolled_holder
    .product_dec_last_scrolled
    .product_dec_last_scrolled_right {
    height: auto !important;
  }

  .banner-bottom-two.new-banner-two .shop-heading,
  .nutrition_facts_tab .nutrition_facts_tab_chart {
    padding: 0;
  }

  .nutrition_facts_tab
    .nutrition_facts_tab_chart
    > div:nth-child(1)
    > div:nth-child(2) {
    width: 90% !important;
  }

  .nutrition_facts_tab h2 {
    padding: 25px;
  }

  .banner-bottom-five.new-five .filter-section-inner {
    padding-top: 100px;
    padding-right: 100px;
  }

  #pdp__gs__chart {
    padding-bottom: 50px;
  }

  #member-on-home .banner-bottom-three.new-banner-three .resrch-prdct {
    width: 325px;

    img {
      left: 0;
    }

    .reserch-tabs {
      left: 0;
      right: 0;
    }
  }

  #member-on-home .banner-bottom-three.new-banner-three .resrch-section {
    padding-left: 18px;
  }
}

.preview-pic .discount_percentage_value {
  background: #2c7765;
  color: #fff;
  padding: 5px 14px 2px 14px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.3;
  font-family: Overpass, sans-serif;
  z-index: 99;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    font-size: 12px;
    line-height: 16.5px;
    padding: 3px 10px 0px 10px;
  }
}

@media (max-width: 768px) {
  .pdp-main-image-wrapper {
    width: 100%;
    height: 100%;
    display: block;
    visibility: visible;
    opacity: 1;
    overflow: hidden;
  }
}

.quarter-circle-top-left .sv__icon {
  top: 35%;
  left: 38%;
}

.quarter-circle-top-right .sv__icon {
  top: 35%;
  right: 38%;
}

.quarter-circle-bottom-left .sv__icon {
  bottom: 35%;
  left: 38%;
}

.quarter-circle-bottom-right .sv__icon {
  bottom: 35%;
  right: 38%;
}

// css for slider
.range-slider {
  margin-top: 50px;
  margin-bottom: 15px;
}

.range-color {
  background-image: linear-gradient(
    to right,
    #93d02f,
    #f3df23,
    #ec5f1e,
    #ee1204
  );
  height: 2px;
  width: 100%;
  position: relative;

  &.green {
    background-image: linear-gradient(to right, #93d02f, #3a7729);
  }

  &:after {
    width: 3px;
    position: absolute;
    left: 63%;
    top: 0;
    background: #fff;
    content: "";
    height: 2px;
  }
}

.range-width-container {
  display: flex;
  justify-content: space-between;
  font-size: 8px;
  font-family: $primary;

  div {
    position: relative;

    &:after {
      position: absolute;
      left: 1px;
      content: "";
      top: 0;
      height: 2px;
      width: 1px;
      background: $subTextColor;
    }

    &:nth-child(5) {
      &:after {
        content: none;
      }
    }
  }
}

.triangle {
  width: 8px;
  height: 6px;
  // border-bottom: solid 6px rgb(200, 30, 50);
  border-left: solid 3px transparent;
  border-right: solid 3px transparent;
  position: absolute;
  top: -6px;
  left: calc(16.1% - 8px);
  background-color: #616161 !important;
  border-bottom-color: #616161 !important;
}

.range-per {
  font-size: 14px;
  color: $darktext;
  font-weight: 700;
  font-family: $primary;
}

.range-text {
  font-size: 10px;
  color: $subTextColor;
  font-family: $primary;
}

.range-tooltip {
  position: absolute;
  top: -45px;
  // left: 15%;
  width: max-content;
}

.accordion__item a {
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    color: #fff !important;
    background: $primary !important;

    & svg {
      path {
        fill: #fff !important;
      }
    }
  }
}

.First.Text h4 {
  color: #2c7765;
  text-align: center;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  max-width: 80%;
  margin: 16px auto;
}

//dynamic gs-chart
.one-pie {
  border-radius: 50%;
  border: 6px solid #cccccc80;

  &.one-pie-selected {
    border-color: #4e4e4ecd;
  }

  & > div:nth-child(2) {
    display: none;
  }

  .scoreTitle {
    display: block !important;
    position: absolute;
    top: -45px;
    left: -45px;
    opacity: 0.85;

    @media (max-width: 567px) {
      left: -62px;
    }
  }

  .chart__svg {
    & .sv__icon {
      left: 43% !important;
      top: 20% !important;
      transform: translate(-20%, -43%);
    }

    & > div:nth-child(2) {
      width: 100% !important;
      height: 100% !important;
      border-radius: 50%;
      border: 0 !important;
      top: 1px;

      .quarter_inner {
        border-radius: 50%;
        transform-origin: center center !important;
        left: -2px;
        top: -1px;
      }
    }
  }
}

.two-pie {
  transition: all 200ms ease-in-out;

  & > div:nth-child(2),
  & > div:nth-child(3) {
    background: transparent !important;
  }

  & > div:nth-child(2) {
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 0 200px 200px 0;

    & .nutrition__image {
      left: 48%;
      top: -48px;
      width: 42%;
      transform: translateX(-48%);

      svg {
        transform: rotate(46deg);
      }

      @media(max-width:767px) {
          top: -42px;
      }
    }

    & .processing__image {
      left: 48%;
      top: -52px;
      width: 42%;
      transform: translateX(-48%);

      svg {
        transform: rotate(316deg);
      }

      @media (max-width: 767px) {
         top: -45px;
      }
    }

    & .environment__image {
      left: 80px;
      top: 130px;
      width: 50%;
      height: 50%;
      background: url("../../../public/images/two-pie/env-left.svg") center left
        no-repeat;
      background-size: cover;
      transform: scale(2.75) rotate(90deg);
      opacity: 0.55;

      svg {
        display: none;
      }

      @media (max-width: 767px) {
           left: 65px;
           top: 116px;
           width: 51%;
      }
    }

    &::after {
      content: "";
      display: block;
      border: 6px solid #cccccc80;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 200px 200px 0 0;
      margin: 0 !important;
      top: -1px !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      opacity: 0.8;
    }

    &.circle-selected {
      &::after {
        border-color: #4e4e4ecd;
      }
    }
  }

  & > div:nth-child(3) {
    top: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0 0 200px 200px;
    border: 0;

    & .processing__image {
      left: 45%;
      top: 72px;
      width: 42%;
      transform: translateX(-45%);

      svg {
        transform: rotate(137deg);
      }

      @media (max-width: 767px) {
        top: 62px;
      }
    }

    & .environment__image {
      top: -78px;
      width: 100%;
      height: 55%;
      background: url("../../../public/images/two-pie/env-right.svg") center
        right no-repeat;
      background-size: cover;
      transform: scale(1.5) rotate(88deg);
      opacity: 0.55;

      svg {
        display: none;
      }

      @media (max-width: 767px) {
         top: -70px;
      }
    }

    & .food___image {
      top: -60px;
      width: 100%;
      height: 50%;
      background: url("../../../public/images/two-pie/food-safety.svg") center
        right no-repeat;
      background-size: cover;
      transform: scale(1.4) rotate(89deg);
      opacity: 0.55;

      svg {
        display: none;
      }

      @media (max-width: 767px) {
        top: -52px;
      }
    }

    &::after {
      content: "";
      display: block;
      border: 6px solid #cccccc80;
      border-top: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 0 200px 200px;
      margin: 0 !important;
      top: 1px !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      opacity: 0.8;
    }

    &.circle-selected {
      &::after {
        border-color: #4e4e4ecd;
      }
    }
  }

  .chart__svg {
    & > div:nth-child(2) {
      left: 0 !important;
      top: -1px !important;
      width: 100% !important;
      border-radius: 200px 200px 0 0;
      border: 0 !important;

      .quarter_inner {
        height: 100% !important;
        border-radius: 200px 200px 0 0;
        transform-origin: bottom center !important;
        bottom: 0;
        left: 0;
      }

      .sv__icon {
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
      }
    }

    & > div:nth-child(3) {
      top: auto;
      right: 0 !important;
      bottom: -1px !important;
      left: auto !important;
      width: 100% !important;
      border-radius: 0 0 200px 200px;
      border: 0 !important;

      .quarter_inner {
        width: 100% !important;
        border-radius: 0 0 200px 200px;
        transform-origin: top center !important;
        right: 0;
      }

      .sv__icon {
        left: 50%;
        right: 50%;
        top: 65%;
        transform: translate(-50%, -65%);
      }
    }
  }
}

.three-pie {
  border-radius: 50%;
  transition: all 200ms ease-in-out;

  & .circle-selected {
    &:after {
      border-color: #4e4e4ecd !important;
    }
  }

  & > div:nth-child(2) {
    background: transparent !important;
    height: 61.5%;
    left: -2px;
    right: 0;
    top: 0;

    &:after {
      content: "";
      border: 6px solid #cccccc80;
      position: absolute;
      left: -2px;
      top: -4px;
      border-right: 0;
      border-bottom: 0;
      border-radius: 200px 0 0 4px;
    }

    .nutrition__image {
      width: 78%;
      top: 28px;
      left: -29px;

      svg {
        transform: rotate(-19deg);
      }
    }

    .processing__image {
      width: 80%;
      top: 32px;
      left: -36px;

      svg {
        transform: rotate(248deg);
      }
    }
  }

  & > div:nth-child(3) {
    background: transparent !important;
    height: 63%;
    top: 0;
    right: -1px;
    left: auto;
    border: 0;

    &:after {
      content: "";
      border: 6px solid #cccccc80;
      position: absolute;
      left: 0;
      top: -4px;
      border-left: 0;
      border-bottom: 0;
      border-radius: 0 200px 4px 0;
    }

    .processing__image {
      width: 78%;
      top: 35px;
      left: 58px;

      svg {
        transform: rotate(22deg);
      }

      @media (max-width: 576px) {
        left: 54px;

        svg {
          transform: rotate(28deg);
        }
      }
    }

    .environment__image {
      width: 100%;
      left: -101px;
      top: 134px;
      background: url("../../../public/images/two-pie/env-right.svg") center
        right no-repeat;
      background-size: cover;
      transform: scale(2.7) rotate(-26deg);
      height: 25%;
      opacity: 0.55;

      svg {
        display: none;
      }

      @media (max-width: 576px) {
        left: -83px;
        top: 112px;
        transform: scale(2.6) rotate(-21deg);
      }
    }
  }

  & > div:nth-child(4) {
    background: transparent !important;
    width: 102%;
    border: 0;
    height: 50%;
    left: -3px;
    right: 0;
    bottom: -6.5px;
    clip-path: polygon(300% 68%, -116% 159%, 14% 5%);

    &:after {
      content: "";
      border: 6px solid #cccccc80;
      position: absolute;
      left: -1px;
      top: 0;
      border-top: 0;
      border-radius: 0px 0px 200px 200px;
    }

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #fff;
    }

    .environment__image {
      bottom: -16px;
      width: 45%;
      left: 42%;
      transform: rotate(313deg) translateX(-42%);
    }

    .food___image {
      bottom: -97px;
      width: 42%;
      left: 39%;
      transform: rotate(43deg) translateX(-40%);

      @media (max-width: 767px) {
        bottom: -83px;
        width: 40%;
      }
    }
  }

  .chart__svg {
    & > div:nth-child(2) {
      background: #f0f7f5;
      position: absolute;
      border-radius: 200px 0 0 0 !important;
      -moz-border-radius: 200px 0 0 0 !important;
      -webkit-border-radius: 200px 0 0 0 !important;
      top: 0;
      left: -5.5px;
      clip-path: polygon(122% 72%, 100% -371%, -30% 99%);
      height: 66% !important;
      border: 0 !important;
      .quarter_inner {
        border-radius: 200px 0 0 0 !important;
        transform-origin: right bottom !important;
      }

      .sv__icon {
        bottom: 35%;
        left: 40%;
      }
    }

    & > div:nth-child(3) {
      background: #f0f7f5;
      position: absolute;
      border-radius: 0 200px 0 0 !important;
      -moz-border-radius: 0 200px 0 0 !important;
      -webkit-border-radius: 0 200px 0 0 !important;
      top: 0;
      left: 51.5%;
      clip-path: polygon(100% 96%, 100% 0%, -320% 0%);
      height: 66% !important;
      border: 0 !important;
      .quarter_inner {
        border-radius: 0 200px 0 0 !important;
        transform-origin: left bottom !important;
      }

      .sv__icon {
        bottom: 33.5%;
        left: 32%;
        transform: translateY(-33.5%);
      }
    }

    & > div:nth-child(4) {
      background: #f0f7f5;
      position: absolute;
      border-radius: 0 0 200px 0 !important;
      -moz-border-radius: 0 0 200px 0 !important;
      -webkit-border-radius: 0 0 200px 0 !important;
      top: 50% !important;
      left: 0;
      clip-path: polygon(212% 101%, 53% 3%, -325% 185%);
      width: 100% !important;
      border: 0 !important;

      .quarter_inner {
        border-radius: 0 0 200px 200px !important;
        transform-origin: center top !important;
        left: 0;
      }

      .sv__icon {
        left: 42.5%;
        top: 42%;
      }
    }
  }
}

//dynamic gs-chart when all 4 gs are active
$active-properties: nutrition, foodSafety, processing, environment;

.one-pie {
  @each $property in $active-properties {
    &.#{$property}-only {
      .scoreTitle {
        img {
          display: none;
        }
        img[data-img="#{$property}"] {
          display: block !important;
        }
      }
      & [class*="quarteree-circle-"] {
        display: none;
      }
      & .chart__svg {
        & [class*="quarter-circle-"] {
          display: none;
        }
        & > div[data-gs="#{$property}"] {
          display: block;
          width: 100% !important;
          height: 100% !important;
          border-radius: 50%;
          border: 0 !important;
          top: 1px;

          .quarter_inner {
            border-radius: 50%;
            transform-origin: center center !important;
            left: 1.5px;
            top: -0.5px;
          }
        }
        & .sv__icon {
          left: 45% !important;
          top: 20% !important;
          transform: translate(-20%, -45%);
        }
      }
    }
  }
}
