.search-bottom-sheet{
   font-family: Overpass;
   background-color: #f7f5f8 !important;
   border-radius: 0.5rem 0.5rem 0 0;

   .ant-drawer-header {
      border: 0 !important;
      padding: 0.75rem;
   }

   .ant-drawer-header-title {
     justify-content: right !important;
   }

    .ant-drawer-body {
        padding: 0 8px !important;
    }

    .filter-wrap {
        padding: 0 !important;

         .accordion-button {
            color: #4e4e4e !important;
         }
      }

}

.search-filter-wrap {
   .filter-selector-wrap {
      flex: 0 0 auto;
      color: #4e4e4e;
      border: 1px solid #999;
      padding: 0.3rem 1rem;
      font-size: 14px;
      border-radius: 1.5rem;
      background-color: transparent;
      outline: none;
}
}
