.suggestions-container {

  .section-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 8px;
  color: #333;
}

.suggestion-item {
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 8px 12px;
  display: block;
  margin: 2px 0;
  text-align: left;
  font-size: 14px;
  color: #333;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e6e6e6;
  }
}
}

