.bottom-sheet-product-card {
  background-color: #fff;
  padding: 16px;
  img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    object-position: center;
  }

  .gs-feel {
    font-size: 0.75rem;
    margin-top: 0.15rem;
  }

  .product-title {
    text-transform: capitalize;
    line-height: 1.6;
    font-size: 1rem;
    margin-bottom: 0;
    min-height: 52px;
    max-height: 52px;
    color: #4e4e4e;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bottom-sheet-progress-score .ant-progress-text {
    font-size: 16px !important;
    padding-top: 2px;
  }
}
