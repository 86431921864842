.camera-scanner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .camera-wrapper {
    width: 100%;
    height: 97vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
  }
}
