.product-card-horizontal {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 16px 10px;
  position: relative;
  border-bottom: 1px solid #f0f0f0;

  .product-image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 4px;
    margin-right: 10px;
  }

  .product-details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .product-name {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.4;
    color: #4e4e4e;
    margin-bottom: 0.25rem;
    text-transform: capitalize;
  }

  .product-brand {
    font-size: 0.75rem;
    color: #2c7765;
    margin: 0;
    text-transform: capitalize;
  }

  .gs-feel {
    font-size: 0.65rem;
    margin-top: 0.25rem;
    margin-bottom: 0;
    color: #4e4e4e;
  }

  .tap-icon {
    color: #9a9a9a;
    position: absolute;
    right: 20px;
    bottom: 20%;
    font-size: 1.35rem;
    opacity: 0.8;
  }
}
