.more-options-wrap {
  background-color: #f7f5f8;
  padding: 2rem 0;
  ul {
    padding: 0.25rem 0;
    display: flex;
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    li {
      background: #fff;
      border-radius: 10px;
      a , button {
        display: flex;
        align-items: center;
        color: #4e4e4e;
        font-size: 16px;
        padding: 0.75rem ;
        line-height: 1.2;
        display: grid;
        grid-template-columns: 18px 1fr;
        align-items: center;
        width: 100%;
        text-align: left;
        gap: 2px;
          span {
            padding-top: 3px;
          }
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      display: flex;
      gap: 12px;
    }
  }
}
