.navigation {
  // display: none;
  // @media (max-width: 767px) {
    font-family: Overpass, 'sans-serif';
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f8f8f8;
    padding-top: 13px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: calc(15px + constant(safe-area-inset-bottom));
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    width: 100%;
    z-index: 99999;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);

    .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.active {
        .nav-icon,
        .nav-label {
          color: #2c7765;
        }
        .scanner {
          filter: grayscale(0) !important;
        }
      }

      .nav-icon {
        font-size: 20px;
        color: #6b6c6d;
        &.scanner {
          width: 24px;
          height: 20px;
          filter: grayscale(1);
        }
      }

      .nav-label {
        margin-top: 8px;
        font-size: 14px;
        color: #4e4e4e;
      }

      &:hover,
      &:active {
        .nav-icon {
          color: #2c7765;
        }
        .nav-label {
          color: #2c7765;
        }
      }
    }
  // }
}


.my-custom-tooltip  {
  position: relative;
  .introjs-tooltip-header {
    position: absolute;
    right: 0;
    z-index: 99;
  }
  .introjs-tooltiptext {
    padding: 12px 16px 10px 16px;
    font-family: Overpass, 'sans-serif' !important;
    font-size: 15px !important;
      strong {
        font-family: Chivo, 'sans-serif' !important;
        margin-bottom: 6px !important;
        line-height: 1.25 !important;
        padding-right: 18px;
        display: inline-block;
        font-size: 16px !important;
      }
  }
  .introjs-bullets {
    padding: 0;
  }

  .introjs-tooltipbuttons {
     a {
        background-color: #2c7765;
        color: #fff;
        font-family: Overpass, 'sans-serif';
        font-weight: 400 !important;
        text-shadow: none;
        border-radius: 6px !important;
        border: 0;
          &.introjs-disabled {
              opacity: 0.75;
          }
     }
  }
}