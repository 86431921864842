@import "../../Styles/variables";

#productBanner {
  margin: 3rem 0 6rem 0;
  h1 {
    font-size: 32px;
    line-height: 44px;
  }
  @media (max-width: 1024px) {
    h1 {
      font-size: 25px;
      line-height: 36px;
    }
  }
  @media (max-width: 767px) {
    margin: 0.5rem 0 0.75rem 0;
    h1 {
      font-size: 17px;
      line-height: 25px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.pdpBrand {
  p {
    font-size: 17px;
    color: $primary;
  }
  @media (max-width: 767px) {
    margin-top: 1.25rem;
    p {
      font-size: 14px;
      line-height: 1.25;
      max-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span {
      font-size: 13px;
      line-height: 1.25;
    }
  }
}

.pdpWrap {
  display: grid;
  grid-template-columns: 10% 85%;
  grid-gap: 5%;

  @media (max-width: 767px) {
    grid-template-columns: 100%;
    grid-gap: 0;
    position: relative;
    top: 0;
    z-index: 99;
  }
}
.pdpMainImg {
  height: 400px;
  width: 100%;

  & > div > img {
    max-height: 400px;
    width: inherit;
    object-fit: contain;
    max-width: 100%;
  }

  @media (max-width: 767px) {
    height: 145px;
    width: 100%;
    margin-top: 1rem;

    & img {
      height: 125px;
      max-width: 100%;
      object-fit: contain;
      width: 100%;
      margin: auto;
    }
  }
}

.pdpImgThumbnails {
  padding: 0;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 5px;
    border: 1px solid $border;
    margin-bottom: 6px;
    transition: 200ms all ease-in-out;

    &.active {
      border-color: $orange;
      border-radius: 5px;
    }

    &:hover {
      border-color: $orange;
      border-radius: 5px;
      cursor: pointer;
    }

    & img {
      padding: 4px;
      height: inherit;
      width: inherit;
      object-fit: contain;
    }
  }

  @media (max-width: 767px) {
    display: none;
  }
}

.gsScore {
  cursor: pointer;
  text-align: center;
  .ant-progress-text {
    font-size: 1.25rem !important;
    padding-top: 4px !important;
    @media (max-width: 768px) {
      font-size: 1rem !important;
      padding-top: 1px !important;
    }
  }
  span {
    font-size: 20px;
    @media (max-width: 768px) {
      margin-top: 1px !important;
      font-size: 14px;
    }
  }

  & > span {
    font-family: $font-primary;
    text-align: center;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: $secondary;
    padding-top: 8px;
  }
}

.sizes {
  border-top: 1px solid $border;
  padding: 2rem 0;
  @media (max-width: 767px) {
    border-top: 0;
    padding: 0rem 0;
  }
}

.values {
  h2 {
    margin-bottom: 1rem;
  }
  .values-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  button {
    border: none;
    color: $primary;
    background: $light-green;
    border-radius: 10px;
    margin: 0 10px 10px 0;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 16px;
    @media (max-width: 576px) {
      border-radius: 6px;
      margin: 0 5px 5px 0;
      padding: 6px 12px;
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    padding-bottom: 0;
    overflow: hidden;
    padding: 0 0.5rem;
    .values-wrap {
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      -ms-overflow-style: none;
      scroll-behavior: smooth;
      max-width: 95vw;
      gap: 3px;
      button {
        flex: 0 0 auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.pdp-fullwidth-close-btn {
  display: none;

  button {
    border: 0;
    background: transparent;
    position: fixed;
    top: 10px;
    right: 25px;
    z-index: 99999999 !important;
    cursor: pointer;
    font-size: 25px;
    padding: 20px;
    width: 10px;
  }
}

.pdpMobileSlider {
  .swiper-pagination-bullets {
    display: flex;
    height: 18px;
    align-items: center;
    justify-content: center;
    span {
      width: 5px;
      height: 5px;
      background: #d9d9d9;
      border-radius: 50%;
      min-width: 5px;
      margin: 3px 3px 0 3px;

      &.swiper-pagination-bullet-active {
        background: #2c7765;
      }
    }
  }
}

#pdpZoomIndicator {
  span {
    font-family: "Overpass";
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #929292;
    padding: 4px 0 0 8px;
  }
}

.w-full-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 999999 !important;
  width: 100vw !important;
  height: 100vh !important;

  & * {
    align-items: center;
    justify-content: center;
    height: inherit !important;
    width: inherit !important;
  }
}

@media (max-width: 768px) {
  .swiper {
    max-width: 95vw;
    overflow-x: hidden;
  }
  .pdpMobileSlider .lazy-load-image-background.lazy-load-image-loaded {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}

.gsScore-wrap {
  flex: 0 0 20%;
}

.pdpRatings {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  .content-text {
    margin-left: 6px;
  }
  @media (max-width: 767px) {
    margin-bottom: 1rem;
  }
}

@media (max-width: 767px) {
  #productDetailPage .ant-progress.ant-progress-circle {
    width: 42px;
    height: 42px;
    position: absolute;
    top: 8px;
    right: 12px;
    .ant-progress-inner {
      width: 42px !important;
      height: 42px !important;
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
      background-color: #fff;
    }
  }

  #productDetailPage .gsScore-wrap .ant-progress.ant-progress-circle {
    position: relative;
    top: 0 !important;
    right: 0 !important;
    width: 48px !important;
    height: 48px !important;
    .ant-progress-inner {
      width: 48px !important;
      height: 48px !important;
      position: relative !important;
      top: 0 !important;
      right: 0 !important;
      background-color: #fff;
    }
  }

  .brandUnclaimed,
  .brandClaimed {
    font-size: 15px;
    svg {
      height: 14px;
      margin-bottom: 2.5px;
    }
  }

  .pdpRatings .content-text {
    font-size: 14px;
  }
}
