.filter-selector-wrap {
  font-family: Chivo;
  .add-filter-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 4px;
    & span {
      line-height: 1.4;
      padding: 1px 0;
    }
  }
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    gap: 0.35rem;
    max-width: 95vw;
    scrollbar-width: none;
    margin-bottom: 0.3rem;

    &::-webkit-scrollbar {
      display: none;
    }
    li {
      flex: 0 0 auto;
      color: #4e4e4e;
      background-color: #fff;
      border: 1px solid #999;
      padding: 0.3rem 0.5rem 0.3rem 1rem;
      font-size: 15px;
      border-radius: 1.5rem;
      margin: 0.25rem 0;
    }
  }
}
