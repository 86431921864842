.bottom-sheet-wrap {
   bottom: 62px;
}
.product-bottom-sheet {
  background: transparent !important;
  padding: 0 !important;
  box-shadow: none !important;
  .ant-drawer-header {
    padding: 12px 20px !important;
    box-shadow: none !important;
  }
  &.z-index-max{
    position: relative !important;
    z-index: 99999999 !important;
  }

  .ant-drawer-body {
    padding: 0 !important;
    box-shadow: none !important;
  }
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}