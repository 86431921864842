.search-listing {
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .product-list {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    max-height: calc(100vh - 290px);
  }

  .product-grid {
    display: grid;
    grid-template-columns: repeat(2, 49%);
    overflow: hidden;
    gap: 5px;
  }

  .no-products-text {
    width: 100%;
    font-size: 14px;
    color: #cacaca;
    margin-top: 20px;
    text-align: center;
  }

  .load-more-btn {
    display: block;
    margin: 20px auto;
    padding: 10px 30px;
    font-size: 16px;
    color: white;
    background-color: #2c7765;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #255d4e;
    }

    &:disabled {
      background-color: #999;
      cursor: not-allowed;
    }
  }

  .scroll-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-color: #4e4e4e;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #333;
    }

    svg {
      font-size: 18px;
    }
  }

  // Search Filter Section (Adjust based on implementation)
  .search-filter-container {
    margin-bottom: 20px;

    .filter-button {
      padding: 10px;
      background-color: #2c7765;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #255d4e;
      }
    }
  }
}
