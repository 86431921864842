.filter-selector-wrap {
  font-family: Chivo;

  &.bottom {
    position: fixed;
    left: 10px;
    bottom: 82px;
    z-index: 99999;
    & ul {
      li {
        background-color: #fff;
      }
    }
  }
  .selected-filters-number {
    background: #2c7765;
    color: #fff;
    border-radius: 100%;
    height: 17px;
    width: 17px;
    font-size: 9px;
    padding: 3px 2px 2px 2px;
    position: absolute;
    right: -5px;
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    gap: 0.35rem;
    max-width: 95vw;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    li {
      flex: 0 0 auto;
      color: #4e4e4e;
      border: 1px solid #999;
      padding: 0.3rem 0.5rem 0.3rem 1rem;
      font-size: 14px;
      border-radius: 1.5rem;
    }
  }
}
