.history-container {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  overflow: auto;
  padding: 1.25rem 1rem;
}

.empty-state {
  text-align: center;
  color: #b0b0b0;
  margin-top: 40%;
  width: 100%;
}

.empty-state-icon {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}

.empty-state-text h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.35rem;
}

.empty-state-text p {
  font-size: 0.75rem;
  color: #a0a0a0;
  max-width: 75%;
  margin: auto;
}
